@import "../../../styles/variables.scss";

$loader-item-size: 3rem;
$bottom-scale: 1.7;
$animation-props: 1s infinite alternate ease;
$gap: $loader-item-size * 0.8;

.wrapper {
  position: absolute;
  width: 200px;
  height: 300px;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  overflow: hidden;

  background-color: transparent;
}

.loader {
  height: 50px;
  width: $loader-item-size * 3 + $gap * 2;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  &__animation {
    display: flex;
    position: relative;

    & .circle {
      position: absolute;
      width: $loader-item-size;
      height: $loader-item-size;

      border-radius: 50%;
      background-color: lighten($app-pink, 10%);

      animation: jump $animation-props;

      z-index: 6;
      top: 0 - $loader-item-size;

      &:nth-of-type(2) {
        left: $loader-item-size + $gap;
        animation-delay: 0.2s;
      }

      &:nth-of-type(3) {
        left: 2 * ($loader-item-size + $gap);
        animation-delay: 0.3s;
      }
    }

    & .shadow {
      width: $loader-item-size;
      height: 0.4rem;

      position: absolute;

      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.6);

      filter: blur(1px);

      z-index: 5;

      animation: shadow $animation-props;

      & ~ .shadow {
        left: $loader-item-size + $gap;
        animation-delay: 0.2s;

        & ~ .shadow {
          left: 2 * ($loader-item-size + $gap);
          animation-delay: 0.3s;
        }
      }
    }
  }
  &__text {
    display: inline-block;
    margin-top: 4rem;
    font-size: 2.4rem;
    color: $app-black;
    text-transform: uppercase;
    letter-spacing: 1rem;
  }
}

@keyframes jump {
  0% {
    top: 0;
    height: 0.5rem;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX($bottom-scale);
  }

  40% {
    height: $loader-item-size;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: -12rem;
  }
}

@keyframes shadow {
  0% {
    transform: scaleX($bottom-scale * 0.9);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.5;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.3;
  }
}

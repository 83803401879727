@import "../../styles/variables.scss";
.page {
  min-height: 100svh;
  background-color: $app-blue;
  padding: 4.5rem 2.4rem;
  display: flex;
  align-items: center;
  overflow: hidden;

  @media screen and (max-width: $bp-large) {
    padding: 2.4rem;
  }
  @media screen and (max-width: $bp-medium) {
    padding: 0;
  }
}

.container {
  display: flex;
  flex: 1 1 100%;
  border-radius: 30px;
  overflow: hidden;
  height: 100%;

  @media screen and (max-width: $bp-medium) {
    border-radius: unset;
    min-height: 100svh;
  }
}

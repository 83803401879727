@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.header {
  height: 7.2rem;
  @media screen and (max-width: $bp-medium) {
    height: 4.8rem;
  }
  &__inner {
    @include container;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1.2rem;

    @media screen and (max-width: $bp-medium) {
      padding-left: 0;
      padding-right: 1.6rem;
    }

    & .btn {
      @include btn-primary;
      padding: 0.8rem 2rem;
      font-size: 1.8rem;

      @media screen and (max-width: $bp-medium) {
        display: none;
      }
    }
  }
}

@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.overlay {
  @include overlay;
  overflow-y: hidden;
  opacity: 0;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  opacity: 0;

  @media screen and (max-width: $bp-medium) {
    top: 0;
    left: 0;
    right: 0;
    width: 100svw;
    transform: none;
  }
}

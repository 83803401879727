@import "../../styles/variables";

.hero {
  flex: 0 1 60%;
  background-color: $app-blue-light-xs;
  padding: 6.8rem 0 9.4rem;

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
      color: $app-black;
      font-family: $font-accent;
      font-weight: 500;
      font-size: 3.2rem;
      letter-spacing: 0.32px;
      &_accent {
        color: $app-blue-hover;
      }
    }
  }

  @media screen and (max-width: $bp-large) {
    display: none;
  }
}

@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  & .signUp-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3.2rem;

    & .input-group {
      display: flex;
      flex-direction: column;

      margin-bottom: 3.7rem;

      &:nth-child(2) {
        margin-bottom: 5.2rem;
      }
      & .label {
        color: $app-black-press;
        font-family: $font-accent;
        font-size: 1.6rem;
        font-weight: 300;
        letter-spacing: 0.16px;

        margin-bottom: 1.1rem;
      }

      & .input-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 30px;
        background: $app-blue-light-xs;
        overflow: hidden;
      }

      & .input {
        flex-grow: 1;
        outline: none;
        border: unset;
        padding: 1.8rem 2.4rem;

        color: $app-black;
        font-family: $font-accent;
        font-size: 1.6rem;
        font-weight: 300;
        letter-spacing: 0.16px;
        line-height: 1;
        background: $app-blue-light-xs;
      }

      & .svg-box {
        @include svg-box;
        width: auto;
        display: none;
        @media screen and (max-width: $bp-large) {
          display: flex;
          margin-left: auto;
          margin-right: 10px;
        }
      }

      & ::placeholder {
        color: $app-grey;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        line-height: inherit;
      }
    }

    & .btn {
      @include btn-primary;
      position: relative;
      margin: 0 auto;
      transition: all 0.3s ease-in-out;

      &:hover,
      &:focus-visible {
        cursor: pointer;
        transform: translateY(-3px);
        box-shadow: 0px 4px 4px -1px rgb(80, 82, 100);
      }
      &:active {
        transform: translateY(2px);
        box-shadow: 0px 2px 2px -1px rgb(80, 82, 100);
      }
      @media screen and (max-width: $bp-large) {
        width: 100%;
        padding: 8px 20px;
      }
    }
  }
}

@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.drag-and-drop-box {
  @include card;
  padding: 6px;
  position: relative;
  z-index: 1000;
  & .drop-area {
    @include card;

    padding: 2.2rem 0;

    @media screen and (max-width: $bp-medium) {
      padding: 1rem 0;
    }

    border: 1px dashed $app-pink;
    background-color: $app-white;
    & .label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      &:hover {
        cursor: pointer;
      }

      @media screen and (max-width: $bp-medium) {
        flex-direction: row;
        gap: 8px;
      }
    }

    & .svg-box {
      @include svg-box;
      width: 7.2rem;
      height: 7.2rem;

      margin-bottom: 0.8rem;

      border-radius: 100%;
      background-color: $app-pink-light;

      transition: all 0.5s ease-out;

      @media screen and (max-width: $bp-medium) {
        height: 48px;
        width: 48px;
        margin-bottom: 0;
        & svg {
          height: 32px;
          width: 32px;
        }
      }
    }

    & .text {
      color: $app-pink;
      font-family: $font-default;
      font-size: 1.8rem;
      font-weight: 400;
      letter-spacing: 0.18px;
    }

    & .input {
      width: 0px;
      height: 0px;
    }
  }
  &:hover,
  :focus-visible {
    cursor: pointer;

    & .svg-box {
      animation: pulse 0.8s infinite;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.gallery-info {
  margin-top: 3.2rem;

  color: $app-blue-light;
  font-family: $font-accent;
  font-size: 4.8rem;
  font-weight: 500;
  letter-spacing: 0.48px;

  @media screen and (max-width: $bp-medium) {
    font-size: 3rem;
  }
}

.gallery-list {
  list-style: none;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.2rem;
  margin-top: 3.2rem;
  margin-bottom: 4.8rem;

  & li {
    aspect-ratio: 1 / 1;
  }

  @media screen and (max-width: $bp-large) {
    margin-top: 2.4rem;
    margin-bottom: 3.2rem;
  }
  @media screen and (max-width: $bp-medium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.btn {
  margin: 0 auto;
  width: max-content;
  @include btn-secondary;
  transition: 0.3s all;
  &:hover,
  &:focus-visible {
    cursor: pointer;
    transform: translateY(-3px);
    box-shadow: 0px 4px 4px -1px rgb(80, 82, 100);
  }
  &:active {
    transform: translateY(2px);
    box-shadow: 0px 2px 2px -1px rgb(80, 82, 100);
  }
}

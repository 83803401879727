@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.card {
  @include card;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $app-blue-light-xs;
  max-width: 44.8rem;

  @media screen and (max-width: $bp-medium) {
    width: 100svw;
    height: 100svh;
    border-radius: unset;
    max-width: none;
  }

  &__inner {
    padding: 4.8rem 6.4rem;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $bp-medium) {
      padding: 4.8rem 1.6rem;
    }
  }
  &__img-box {
    @include card;
    width: 32rem;
    height: 32rem;
    margin-bottom: 2.4rem;

    overflow: hidden;

    @media screen and (max-width: $bp-medium) {
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__text {
    margin-bottom: 2.4rem;

    color: $app-black;
    font-family: $font-accent;
    font-size: 3.2rem;
    font-weight: 500;
    letter-spacing: 0.32px;
    text-align: center;

    @media screen and (max-width: $bp-medium) {
      font-size: 2.4rem;
    }
  }
  &__input-group {
    position: relative;
    & .input {
      border-radius: 30px;
      background: $app-white;
      outline: none;
      border: unset;
      padding: 1.4rem 5rem 1.4rem 2.4rem;
      width: 100%;

      color: $app-grey-2;
      font-family: $font-accent;
      font-size: 1.6rem;
      font-weight: 300;
      letter-spacing: 0.16px;

      @media screen and (max-width: $bp-medium) {
        font-size: 1.4rem;
        letter-spacing: 0.14px;
      }
    }
  }
  & .btn {
    outline: none;
    border: unset;
    background-color: transparent;
    transition: 0.3s all ease-out;

    @media screen and (max-width: $bp-medium) {
      width: 2.4rem;
      height: 2.4rem;
    }
    &_close {
      position: absolute;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 100%;
      border: 1px solid $app-black-disable;
      top: 2.4rem;
      right: 2.4rem;

      &:hover {
        background-color: #00000013;
      }

      @media screen and (max-width: $bp-medium) {
        top: 1.6rem;
        right: 1.6rem;
      }
    }
    &_copy {
      position: absolute;
      right: 0;
      top: 3px;
      height: 100%;

      margin-right: 1.6rem;

      &:hover {
        transform: scale(1.1);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

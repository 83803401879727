@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.section {
  grid-column: 1 / 2;
  grid-row: 1 / -1;

  border-radius: 3.2rem;
  background-color: $app-white;
  @media screen and (max-width: $bp-large) {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }
  @media screen and (max-width: $bp-medium) {
    border-radius: 1.6rem;
  }
  &__inner {
    padding: 2.4rem 2.4rem 4.8rem;

    @media screen and (max-width: $bp-large) {
      padding: 1.6rem 1.6rem 3.2rem;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: $bp-medium) {
      margin-bottom: 2.4rem;
    }
  }
  &__title {
    color: $app-black;
    font-family: $font-accent;
    font-size: 4.8rem;
    font-weight: 500;

    letter-spacing: 0.48px;

    @media screen and (max-width: $bp-medium) {
      font-size: 3rem;
    }
  }
}

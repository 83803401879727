@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.main {
  flex-grow: 1;
  background-color: $app-blue-light-xs;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__inner {
    @include container;
    display: flex;
    flex-grow: 1;

    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    display: grid;
    grid-template-columns: minmax(0px, 1fr) 21rem;
    grid-template-rows: 16rem repeat(2, auto);
    grid-column-gap: 3rem;
    grid-row-gap: 2.4rem;

    @media screen and (max-width: $bp-large) {
      grid-template-columns: 23rem minmax(0px, 1fr);
      grid-template-rows: 156px auto;
      grid-column-gap: 1.6rem;
      grid-row-gap: 1.6rem;
    }
    @media screen and (max-width: $bp-medium) {
      grid-template-rows: 80px auto;
      padding: 2.4rem 0;
    }
  }
}

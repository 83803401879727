@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.auth {
  flex: 0 0 40.5%;
  background-color: $app-white;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2.4rem;

  position: relative;

  &__inner {
    width: 100%;
    max-width: 34.5rem;

    @media screen and (max-width: $bp-large) {
      max-width: 30.6rem;
    }
    @media screen and (max-width: $bp-medium) {
      max-width: 100%;
      padding: 4.8rem 1.6rem 2.4rem;
    }
  }

  & .btn {
    display: none;
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    width: 3.2rem;
    height: 3.2rem;
    outline: none;
    border: unset;
    background-color: transparent;

    border-radius: 100%;
    border: 1px solid $app-black-disable;

    transition: all 0.3s;
    &:hover {
      cursor: pointer;
      background-color: rgb(240, 240, 240);
    }

    & .svg-box {
      @include svg-box;
    }

    @media screen and (max-width: $bp-large) {
      display: block;
    }

    @media screen and (max-width: $bp-medium) {
      width: 2.4rem;
      height: 2.4rem;
      top: 1.6rem;
      right: 1.6rem;
    }
  }

  @media screen and (max-width: $bp-large) {
    flex: 0 0 100%;
  }
}

/* COLORS */
$app-blue: #6b7aff;
$app-blue-2: #7d95fc;
$app-blue-light: #e0e7ff;
$app-blue-light-xs: #f2f5ff;
$app-white: #ffffff;
$app-black: #22222b;
$app-blue-hover: #5977f8;
$app-grey: #b0b8dc;
$app-grey-2: #a8adc8;
$app-pink: #ff61e6;
$app-pink-light: #fff5fd;
$app-black-disable: #c9ccd9;
$app-black-press: #616e9d;
$app-green: #75d26d;

/* FONTS */
$font-default: Helvetica, sans-serif;
$font-accent: Navigo, sans-serif;

/* BREAKPOINTS */
$bp-large: 1439px;
$bp-medium: 767px;
$bp-small: 319px;

:export {
  bpMedium: $bp-medium;
}

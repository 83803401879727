@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.call-for-action {
  position: relative;
  & .btn {
    @include btn-primary;
    padding: 0.95rem 2rem;
    font-size: 1.8rem;
    letter-spacing: 0.18px;
    transition: all 0.2s ease-in;
    &:hover,
    &:focus-visible {
      cursor: pointer;
      transform: translateY(-3px);
      box-shadow: 0px 4px 4px -1px rgb(80, 82, 100);
    }
    &:active {
      transform: translateY(2px);
      box-shadow: 0px 2px 2px -1px rgb(80, 82, 100);
    }
  }
  & .input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  @media screen and (max-width: $bp-medium) {
    display: none;
  }
}

@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.section {
  &__inner {
    position: relative;

    & .drag-n-drop-box {
    }
  }
  @media screen and (max-width: $bp-large) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  @media screen and (max-width: $bp-medium) {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    padding-right: 1.6rem;
    padding-left: 1.6rem;
  }
}
